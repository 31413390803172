body {
  overflow-x: hidden;
}
.text-gray {
  color: #b4b4b4;
}
.center {
  text-align: center;
}
.cursor-pointer {
  cursor: pointer !important;
}
.m-width-100 {
  min-width: 100px;
}
#root::before {
  content: "";
  width: 100vw;
  height: 100vh;
  opacity: 0.05;
  position: absolute;
  background-size: cover;
  background-image: url(../img/vr-bg.svg);
}
.btn-close {
  -webkit-filter: invert(100%);
  filter: invert(100%);
  opacity: 1;
}
.navbar {
  top: 0px;
  transition: all 0.5s linear 0s;
  .navbar-brand {
    display: flex;
    flex-direction: column;
    img {
      width: 112px;
      object-fit: contain;
    }
    p {
      margin-bottom: 0;
      color: #04ecfc;
      font-style: italic;
      span {
        color: red;
      }
    }
  }
}
.text-cyan {
  color: cyan;
}
.bg-cyan {
  background-color: cyan;
}
.text-red {
  color: #ff0000ad;
}
.h-150 {
  height: 150px;
}
.page-wrapper {
  padding-bottom: 2rem;
}
.page-wrapper {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 40vw;
    background-color: cyan;
    border-radius: 50%;
    filter: blur(300px);
    opacity: 0.4;
    left: -20vw;
    z-index: -1000;
    height: 100%;
    top: 0;
  }
  &::after {
    position: absolute;
    content: "";
    background: red;
    width: 40vw;
    border-radius: 50%;
    filter: blur(300px);
    opacity: 0.4;
    right: -20vw;
    height: 100%;
    top: 0;
    z-index: -10000;
  }
}
.signup {
  .auth-card {
    max-width: 100%;
  }
}
.auth-card {
  margin: auto;
  max-width: 540px;
  border-radius: 1rem;
  padding: 2.5rem 3rem;
  background: rgba(18, 120, 129, 0.2);
  .opt-control {
    display: flex;
    gap: 1rem;
    > div {
      gap: 1rem;
    }
    .form-control {
      font-size: 34px;
      line-height: 0;
      padding: 0;
      width: 16.6666% !important;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
      }
    }
  }
  .input-icon {
    .form-control {
      padding-left: 2.75rem;
    }
  }
  .form-check-input {
    margin: 0;
    box-shadow: none;
    border-radius: 0 !important;
    border: 1px solid rgba(0, 255, 255, 0.3607843137);
    &:checked {
      background-color: rgba(0, 255, 255, 0.3607843137);
    }
  }
}
.form-control {
  min-height: 52px;
  color: #b4b4b4;
  font-size: 0.87rem;
  border-radius: 7px;
  background: transparent;
  padding: 0.875rem 1.125rem;
  border: 1px solid #00ffff5c;
  &:focus,
  &:focus-within,
  &:focus-visible {
    color: #b4b4b4;
    box-shadow: none;
    background: transparent;
    border: 1px solid #00ffff5c;
  }
  &::placeholder {
    color: #b4b4b4;
  }
}
.input-icon {
  position: relative;
  .icon {
    top: 50%;
    left: 1rem;
    color: #b4b4b4;
    position: absolute;
    transform: translateY(-50%);
  }
}
.form-check-input[type="checkbox"] {
  border-radius: 0.375rem;
}
.form-check-input {
  width: 1.025rem;
  height: 1.025rem;
  margin-top: 0.1875rem;
  vertical-align: top;
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid var(--bs-gray-500);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.gradient-btn {
  z-index: 0;
  border: none;
  outline: none;
  position: relative;
  border-radius: 7px;
  padding: 0.875rem 2rem;
  background: linear-gradient(to right, #00ffff5c, #ff00005c);
  &.btn-sm {
    padding: 0.65rem;
    &::after {
      font-size: 12px;
    }
  }
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
  &::before {
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    z-index: -1;
    content: "";
    border-radius: 7px;
    position: absolute;
    background-color: #002121;
    transition: all linear 200ms;
  }
  &::after {
    font-size: 14px;
    transition: 200ms;
    color: transparent;
    content: attr(data);
    background: #b4b4b4;
    -webkit-background-clip: text;
  }
  &:hover {
    &::before {
      opacity: 50%;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
    }
    &::after {
      color: #b4b4b4;
    }
  }
}

.theme-select {
  .css-1fdsijx-ValueContainer {
    padding: 0;
    .css-17uncc1-singleValue {
      margin: 0;
      .css-1jn2vs-Input {
        margin: 0;
        padding: 0;
      }
    }
  }
  .css-1hb7zxy-IndicatorsContainer {
    .css-1u9des2-indicatorSeparator {
      margin: 0.875rem 0.5rem;
      background-color: #00ffff5c;
    }
    .css-1xc3v61-indicatorContainer {
      padding: 0;
      color: #00ffff5c;
    }
  }
}
.btn-light {
  min-height: 53px;
  padding-inline: 2rem;
  font-size: 0.875rem;
}
.card-cyan-bg {
  background: rgba(18, 120, 129, 0.2);
  &.border {
    border-color: #076870 !important;
  }
  &.hover-bg {
    &:hover {
      cursor: pointer;
      background: linear-gradient(to right, #252c2c, #0d4444);
    }
  }
}
.active-plan {
  border: 1px solid #043638;
  .active-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    .plan-text {
      padding: 1rem;
      width: 85%;
      color: #fff;
      margin-top: -30px;
      text-align: center;
      border-radius: 25px;
      background: #043638;
    }
  }
}
.icon-rocket {
  height: 54px;
  width: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  border-color: #3d5f5f !important;
  padding-top: 5px;
  border-width: 2px !important;
  img {
    width: 100%;
    height: 100%;
    margin: 4px 4px 0 0;
    object-fit: contain;
  }
}
.lottie-wrapper {
  height: 100vh;
  width: 100vw;
  div {
    height: 100%;
    display: flex;
    align-items: center;
    svg {
      height: 30% !important;
    }
  }
}
.cyan-pattern {
  background: linear-gradient(to right, #058080, #085d5d);
  border: 0;
  padding: 0.5rem 1.25rem;
  color: #b4b4b4;
  font-size: 1.2rem;
  position: absolute;
  bottom: 0;
  right: 0;
  border-top-left-radius: 60px;
  height: 30px;
  width: 64px;
  opacity: 0.4;
}
.icons-dashboard {
  height: 60px;
  width: 74px;
  display: flex;
  padding: 6px;
  border-radius: 8px;
  align-items: center;
  background: #0fbbc7;
  justify-content: center;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
.card-red-bg {
  border-color: #621e1e !important;
  background: #261212;
}
.card-opaque {
  background: #2827278f;
}
.card-body {
  table {
    td,
    th {
      font-size: 12px;
      color: #b4b4b4;
      background: transparent;
    }
  }
}
.react-sweet-progress-symbol {
  display: none !important;
}
.custom-toggle {
  outline: 0;
  border: 0;
  background: transparent !important;
  &::after {
    display: none;
  }
  &.show,
  &:hover,
  &:active,
  &.btn-primary,
  &:focus-visible,
  &:focus {
    background: transparent !important;
  }
}

.ad-box {
  height: 120px;
  width: 100%;
  video,
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.header-toast {
  padding: 0;
  margin-top: 1.1rem;
  width: 420px !important;
  .Toastify__toast--default {
    margin: 0;
    font-size: 12px;
    padding: 0.5rem;
    min-height: inherit;
    background: #162427;
    .Toastify__toast-body {
      padding: 0;
      .gradient-btn {
        margin-inline: 0.5rem 0.25rem;
        &::after {
          font-size: 12px;
        }
      }
    }
    .Toastify__close-button {
      display: none;
    }
  }
}
footer {
  padding: 1rem 0;
  text-align: center;
  border-top: 1px solid #076870;
  p {
    margin: 0;
    font-size: 13px;
    color: #058080;
  }
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.modal {
  .modal-footer,
  .modal-header {
    border-color: rgb(7 104 112 / 30%);
  }
  .btn-close {
    opacity: 0.4;
    &:focus {
      box-shadow: none;
    }
  }
  .modal-sm {
    .modal-footer {
      justify-content: center;
    }
  }
}
.claim-listing {
  display: flex;
  font-size: 12px;
  list-style: none;
  padding: 0.5rem 1rem;
  margin-inline: -1rem;
  justify-content: space-between;
  .claim-icon {
    width: 40px;
    height: 40px;
    display: flex;
    font-size: 1.25rem;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    background: rgb(0 243 243 / 20%);
    box-shadow: 0 0 10px rgb(34 150 150 / 50%);
    svg {
      box-shadow: 0 0 10px rgb(34 150 150 / 50%);
    }
  }
  &:last-child {
    padding-bottom: 0;
  }
  &:first-child {
    padding-top: 0;
  }
}
.uploadFile {
  padding: 3rem;
  max-width: 500px;
  margin: 3rem auto;
  border-radius: 25px;
  border: 1px dashed #b4b4b4;
  cursor: pointer;
  label {
    display: flex;
    align-items: center;
    flex-direction: column;
    svg {
      font-size: 5rem;
    }
    small {
      font-size: 0.75rem;
    }
  }
}

.imgGallery {
  padding: 0;
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  li {
    height: 130px;
    position: relative;
    width: calc(11.11% - 1rem);
    border: 1px solid #727272;
    .trash_icon {
      bottom: 0;
      opacity: 0;
      width: 100%;
      padding: 7px;
      height: 30px;
      position: absolute;
      background: rgb(0 0 0 / 50%);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      .trash_icon {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}
.img-preview {
  padding: 0;
  margin: 2rem 0 0;
  li {
    padding: 5px;
    display: flex;
    margin: 0.5rem 0;
    align-items: center;
    border-radius: 10px;
    justify-content: space-between;
    background: rgba(18, 120, 129, 0.2);
    span {
      width: 150px;
      height: 75px;
      video,
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.theme-nav {
  .dropdown-menu {
    border: none;
    .dropdown-item {
      gap: 0.5rem;
      display: flex;
      font-size: 0.875rem;
      align-items: center;
      &.nolink {
        color: cyan;
        &:hover {
          background: transparent;
        }
      }
      &:active,
      &.active {
        background: transparent;
      }
    }
  }
}
.referred-users {
  padding: 1.5rem 0;
  border-radius: 1rem;
  background: rgba(18, 120, 129, 0.2);
  .referred-users-listing {
    padding: 0;
    list-style: none;
    li {
      gap: 1rem;
      display: flex;
      align-items: center;
      padding: 0.85rem 1.5rem;
      border-top: 1px solid rgb(0 255 255 / 10%);
      &:last-child {
        border-bottom: 1px solid rgb(0 255 255 / 10%);
      }
      span {
        width: 50px;
        height: 50px;
        display: flex;
        overflow: hidden;
        border-radius: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
.refer-email {
  display: flex;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgb(0 255 255 / 10%);
  .gradient-btn,
  .gradient-btn:before {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
