@media (max-width: 1199px) {
  .ad-box {
    height: 160px;
  }
  .icons-dashboard {
    width: 60px;
  }
  .page-wrapper {
    &::after {
      right: 0;
    }
  }
}
@media (max-width: 576px) {
  .container {
    padding-inline: 1.5rem;
  }
  .auth-card {
    padding-inline: 1rem;
    .opt-control {
      > div {
        gap: 0.4rem;
      }
      .form-control {
        font-size: 30px;
      }
    }
  }
  .ad-box {
    height: inherit;
  }
}
