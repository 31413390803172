@font-face {
  font-family: "Prometo";
  src: url("../fonts/Prometo.woff2") format("woff2"),
    /* Modern Browsers */ url("../fonts/Prometo.woff") format("woff");
  /* Legacy Browsers */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Digital";
  src: url("../fonts/digital.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: "Prometo";
}

.time-font {
  font-family: "Digital" !important;
}